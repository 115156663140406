import * as React from 'react'

import { cn } from '@design-system/lib/utils'

import { Text } from '../typography/text'

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  appearance?: 'chromeless' | 'default'
  error?: string | string[]
  hint?: React.ComponentType
  label: string
  resize?: 'none' | 'vertical'
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      className,
      appearance = 'default',
      label,
      name,
      rows = 4,
      error,
      resize = 'vertical',
      hint: Hint,
      id,
      ...props
    },
    ref,
  ) => {
    return (
      <div className="group flex w-full flex-col gap-y-xs">
        <div className="flex items-start gap-x-xs">
          <label
            className={cn(
              'text-subtle transition-colors duration-200 font-input-s group-has-[:focus-visible]:text-primary group-has-[:disabled]:opacity-50',
              appearance === 'chromeless' ? 'sr-only' : '',
            )}
            htmlFor={id}
          >
            {label}
          </label>
          {Hint && <Hint />}
        </div>
        <textarea
          className={cn(
            'flex w-full rounded-md border bg-input py-m outline-none transition-colors font-text-s placeholder:text-input hover:border-input disabled:cursor-not-allowed disabled:opacity-50 group-hover:disabled:border-input',
            appearance === 'chromeless'
              ? 'border-none px-0'
              : 'px-l focus-visible:ring focus-visible:ring-primary',
            resize === 'vertical' && 'resize-y',
            resize === 'none' && 'resize-none',
            error && 'border-error hover:border-error',
            className,
          )}
          id={id}
          name={name}
          rows={rows}
          {...props}
          ref={ref}
        />
        {error && (
          <Text className="mt-xs" color="error" variant={'p-s'}>
            {error}
          </Text>
        )}
      </div>
    )
  },
)
Textarea.displayName = 'Textarea'

export { Textarea }
