import * as React from 'react'

import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu'

import { cn } from '@design-system/lib/utils'

const NavigationMenuItem = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Item>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.Item
    className={cn('block', className)}
    ref={ref}
    {...props}
  />
))

NavigationMenuItem.displayName = NavigationMenuPrimitive.Item.displayName

export { NavigationMenuItem }
