import { forwardRef } from 'react'

import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

import { cn } from '@design-system/lib/utils'

export type TextColorVariantTypes = VariantProps<typeof textVariants>['color']

const textVariants = cva('block', {
  variants: {
    variant: {
      display: 'font-display-text',
      'p-m': 'font-text-m',
      'p-m-bold': 'font-text-m-bold',
      'p-s': 'font-text-s',
      'p-s-bold': 'font-text-s-bold',
      'p-xs': 'font-text-xs',
    },
    color: {
      default: 'text',
      subtle: 'text-subtle',
      input: 'text-input',
      disabled: 'text-disabled',
      inverse: 'text-inverse',
      link: 'text-link',
      'link-hover': 'text-link-hover',
      primary: 'text-primary',
      'primary-hover': 'text-primary-hover',
      secondary: 'text-secondary',
      error: 'text-error',
      success: 'text-success',
    },
  },
  defaultVariants: {
    variant: 'p-m',
  },
})

export type TextColorVariant = VariantProps<typeof textVariants>['color']
export interface TextProps
  extends Omit<React.HTMLAttributes<HTMLSpanElement>, 'color'>,
    VariantProps<typeof textVariants> {}

/**
 * __Text__
 *
 * Text component. Use this component to render text or simply use a span with the appropriate class.
 *
 * @example <Text variant="p-s">Hello world!</Text>
 */
const Text = forwardRef<HTMLSpanElement, TextProps>(
  ({ children, variant, className, color, ...props }, ref) => {
    return (
      <span
        className={cn(textVariants({ variant, color }), className)}
        ref={ref}
        {...props}
      >
        {children}
      </span>
    )
  },
)

Text.displayName = 'Text'
export { Text }
