import * as React from 'react'

import * as TabsPrimitive from '@radix-ui/react-tabs'

import { cn } from '@design-system/lib/utils'

/**
 * __Tabs__
 *
 * Tabs are used to organize content into different sections.
 * This is the root component required for state management.
 */
const Tabs = TabsPrimitive.Root

/**
 * __TabsList__
 *
 * TabsList is used to render the list of tabs (TabTriggers).
 *
 * @see {@link TabsTrigger}
 * @see {@link TabsContent}
 */
const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    className={cn(
      'flex h-8 items-center justify-start gap-x-s border-b',
      className,
    )}
    ref={ref}
    {...props}
  />
))
TabsList.displayName = TabsPrimitive.List.displayName

/**
 * __TabsTrigger__
 *
 * TabsTrigger is used to render the trigger for each tab.
 * This is the element that the user will click on to change the active tab.
 */
const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    className={cn(
      'ring-offset-primary relative inline-flex h-8 items-center justify-center whitespace-nowrap rounded-t-md border-b-2 border-transparent px-m transition-all font-text-s-bold hover:bg-primary-subtle focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:border-primary data-[state=active]:text-primary',
      className,
    )}
    ref={ref}
    {...props}
  />
))
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

/**
 * __TabsContent__
 *
 * TabsContent is used to render the content of the active tab.
 * This is the content that will change when the user selects a different tab via the tabs trigger.
 *
 * @example
 * ```tsx
 * <TabsContent value="first-tab">
 *   <div>Content for the first tab</div>
 * </TabsContent>
 * <TabsContent value="second-tab">
 *   <div>Content for the second tab</div>
 * </TabsContent>
 * ```
 */
const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    className={cn(
      'ring-offset-primary mt-xxl focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 data-[state=inactive]:hidden',
      className,
    )}
    ref={ref}
    {...props}
  />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsContent, TabsList, TabsTrigger }
