import * as React from 'react'

import { Link } from '@radix-ui/react-navigation-menu'
import { NavLink } from '@remix-run/react'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

import { cn } from '@design-system/lib/utils'

const linkItemVariants = cva(
  'grid md:grid-cols-[1rem_1fr] items-center gap-x-s h-12 rounded-r-sm py-m w-full font-text-s hover:bg-neutral-hover text-subtle hover:text-link before:absolute before:inset-y-0 before:left-0 before:w-xs [&.active]:font-text-s-bold [&.active]:text-link focus-visible:bg-neutral-hover',
  {
    variants: {
      variant: {
        default:
          'before:rounded-r-sm [&.active]:bg-primary-subtle [&.active]:before:bg-primary px-xl',
        'sub-item': 'bg-inherit before:bg-transparent',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

export interface LinkItemProps
  extends React.ComponentPropsWithoutRef<typeof Link>,
    VariantProps<typeof linkItemVariants> {
  asChild?: boolean
  icon?: React.ComponentType<{ size?: number | string }>
  prefetch?: 'intent' | 'viewport' | 'none'
}

const NavigationMenuLink = React.forwardRef<
  React.ElementRef<typeof NavLink>,
  LinkItemProps
>(
  (
    {
      className,
      prefetch = 'intent',
      variant,
      icon: Icon,
      children,
      href,
      ...props
    },
    ref,
  ) => {
    return (
      <div className="relative w-full" data-link-item>
        <Link
          asChild
          className={cn(linkItemVariants({ variant }), className)}
          ref={ref}
          {...props}
        >
          <NavLink prefetch={prefetch} to={href!}>
            {Icon ? <Icon size={16} /> : <span />}
            <span className="sr-only md:not-sr-only">{children}</span>
          </NavLink>
        </Link>
      </div>
    )
  },
)

NavigationMenuLink.displayName = NavLink.displayName

export { NavigationMenuLink }
