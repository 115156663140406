import { useMemo } from 'react'

import { cn } from '../../../lib/utils'

export type CodeProps = {
  children: string
}

/**
 * __Code__
 *
 * Monospaced text with neutral bg and subtle text color.
 *
 * Use for showing text which should be treated as code or is a symbol for something else.
 */
export const Code = ({ children }: CodeProps) => {
  return (
    <code
      className="inline-flex text-nowrap rounded bg-neutral p-s font-mono text-xs leading-none text-subtle"
      title={children}
    >
      <span className="truncate">{children}</span>
    </code>
  )
}

Code.displayName = 'Code'

export type CodeBlockProps = {
  /** Text inside the code block - limited to text here intentionally */
  children: string | Record<string, unknown>
  /**
   * Error message to attached to the code block if relevant
   */
  error?: string
}

/**
 * __CodeBlock__
 *
 * Use for displaying code snippets or JSON objects. For single line or word code snippets, use the `Code` component.
 *
 */
export const CodeBlock = ({ children, error }: CodeBlockProps) => {
  const formatted = useMemo(() => {
    if (typeof children === 'string') {
      return children
    }
    return JSON.stringify(children, null, 2)
  }, [children])
  return (
    <pre
      className={cn(
        'overflow-auto whitespace-pre-wrap rounded border border-border p-m font-mono text-xs',
        error && 'border-error',
      )}
    >
      <code>{formatted}</code>
    </pre>
  )
}

CodeBlock.displayName = 'CodeBlock'
