import { useContext, useState, createContext, useMemo } from 'react'

import { ChevronsUpDown } from 'lucide-react'

import { cn } from '../../../lib/utils'
import { Button, type ButtonProps } from '../button/button'
import {
  CommandEmpty,
  CommandInput,
  CommandItem,
  Command,
  CommandList,
  CommandGroup,
  type CommandProps,
} from '../command/command'
import { Label } from '../label/label'
import { Popover, PopoverContent, PopoverTrigger } from '../popover'

const ComboBoxStateProvider = createContext<ComboBoxState>({
  isOpen: false,
  value: '',
  onValueChange: () => {},
  close: () => {},
  open: () => {},
})

export interface ComboBoxState {
  close: () => void
  isOpen: boolean
  onValueChange?: (value: string) => void
  open: () => void
  value: string
}

export interface ComboBoxTriggerProps {
  /**
   * Optional children to render in the trigger button. If none provided it will instead render the current value.
   */
  children?: React.ReactNode
  /**
   * Optional label to render above the trigger button.
   */
  label?: string
}

export const Trigger = ({
  children,
  ...props
}: ComboBoxTriggerProps & ButtonProps) => {
  const state = useContext(ComboBoxStateProvider)

  if (props.label) {
    return (
      <div
        className={cn(
          'flex min-w-32 flex-col-reverse gap-y-xs',
          props.className,
        )}
      >
        <PopoverTrigger asChild>
          <Button
            aria-expanded={state.isOpen}
            className="peer min-w-32"
            rightIcon={ChevronsUpDown}
            role="combobox"
            size="small"
            variant="input"
            {...props}
          >
            {children ?? state.value}
          </Button>
        </PopoverTrigger>
        <Label htmlFor={props.id}>{props.label}</Label>
      </div>
    )
  }

  return (
    <>
      {props.label && <Label>{props.label}</Label>}
      <PopoverTrigger asChild>
        <Button
          aria-expanded={state.isOpen}
          className="min-w-32"
          rightIcon={ChevronsUpDown}
          role="combobox"
          size="small"
          variant="input"
          {...props}
        >
          {children ?? state.value}
        </Button>
      </PopoverTrigger>
    </>
  )
}

export interface ComboboxProps {
  /**
   * The combobox elements
   */
  children: React.ReactNode
  /**
   * Whether the combobox is open by default.
   */
  defaultOpen?: boolean
  /**
   * The initial value of the combobox.
   */
  defaultValue?: string
  /**
   * Callback when the new value is selected.
   */
  onValueChange?: (value: string) => void
}

/**
 * __Combobox__
 */
export const Root = ({
  children,
  defaultValue,
  onValueChange,
  defaultOpen = false,
}: ComboboxProps) => {
  const [open, setOpen] = useState(defaultOpen)
  const [value, setValue] = useState<string>(defaultValue || '')

  const state = useMemo(() => {
    return {
      isOpen: open,
      onValueChange: (value: string) => {
        setValue(value)
        if (onValueChange) {
          onValueChange(value)
        }
      },
      value: value,
      close: () => setOpen(false),
      open: () => setOpen(true),
    }
  }, [value, setValue, onValueChange, open])
  return (
    <ComboBoxStateProvider.Provider value={state}>
      <Popover onOpenChange={setOpen} open={open}>
        {children}
      </Popover>
    </ComboBoxStateProvider.Provider>
  )
}

Trigger.displayName = 'ComboBoxTrigger'

export const Content = ({
  children,
  filter,
  shouldFilter,
  className,
}: { children: React.ReactNode } & CommandProps) => {
  const state = useContext(ComboBoxStateProvider)
  return (
    <PopoverContent className={cn('p-0', className)}>
      <Command
        defaultValue={state.value}
        filter={filter}
        shouldFilter={shouldFilter}
      >
        <CommandList>{children}</CommandList>
      </Command>
    </PopoverContent>
  )
}

export const Empty = CommandEmpty

export const Input = CommandInput

export interface CommandItemProps {
  /**
   * What to render in the item.
   */
  children: React.ReactNode
  /**
   * Callback when the item is selected.
   */
  onSelect?: (value: string) => void
  /**
   * The value of the item.
   */
  value: string
}

export const Item = ({ onSelect, value, children }: CommandItemProps) => {
  const state = useContext(ComboBoxStateProvider)

  return (
    <CommandItem
      onSelect={(currentValue) => {
        if (state.onValueChange) {
          state.onValueChange(currentValue)
        }
        state.close()

        if (onSelect) {
          onSelect(currentValue)
        }
      }}
      value={value}
    >
      {children}
    </CommandItem>
  )
}

export const Group = CommandGroup
