import * as React from 'react'

import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu'
import { NavigationMenuViewport } from '@radix-ui/react-navigation-menu'

import { cn } from '@design-system/lib/utils'

const NavigationMenu = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Root>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Root
    className={cn(
      'no-scrollbar fixed z-10 grid h-screen grid-rows-[6rem_1fr_6rem] overflow-y-auto py-l pr-s max-md:border max-md:border-r md:min-w-52 md:pr-xl',
      className,
    )}
    ref={ref}
    {...props}
  >
    {children}
    <NavigationMenuViewport />
  </NavigationMenuPrimitive.Root>
))
NavigationMenu.displayName = NavigationMenuPrimitive.Root.displayName

export { NavigationMenu }
