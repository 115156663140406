import { forwardRef } from 'react'

import { Slot } from '@radix-ui/react-slot'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

import { cn } from '@design-system/lib/utils'

const headingVariants = cva('block', {
  variants: {
    /**
     * The default variant.
     */
    variant: {
      h1: 'font-heading-h1',
      h2: 'font-heading-h2',
      h3: 'font-heading-h3',
      h4: 'font-heading-h4',
    },
  },
  defaultVariants: {
    variant: 'h1',
  },
})

// default variant to element map else use `asChild` to override
const variantToElementMap = {
  h1: 'h2',
  h2: 'h3',
  h3: 'h4',
  h4: 'h5',
} as const

export interface HeadingProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof headingVariants> {
  /**
   * If true, the component will be rendered as a child of the Slot component.
   */
  asChild?: boolean
  /**
   * The id of the element.
   */
  id?: string
}

/**
 * __Heading__
 *
 * Heading component.
 *
 * You can alternatively use the utility classes directly (e.g. `font-h1`, `font-h2`...).
 */
const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ asChild, children, id, variant, className, ...props }, ref) => {
    const Comp = asChild ? Slot : variantToElementMap[variant || 'h2']
    return (
      // @ts-expect-error props don't work for polymorphic components
      <Comp
        className={cn(headingVariants({ variant, className }), className)}
        id={id}
        ref={ref}
        {...props}
      >
        {children}
      </Comp>
    )
  },
)

Heading.displayName = 'Heading'
export { Heading }
