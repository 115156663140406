import * as React from 'react'

import * as AvatarPrimitive from '@radix-ui/react-avatar'

import { cn } from '@design-system/lib/utils'

type AvatarSize = 'small' | 'medium'

type AvatarProps = {
  appearance?: 'primary' | 'secondary' | 'neutral'
  size?: AvatarSize
} & React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>

const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  AvatarProps
>(({ className, appearance, size = 'medium', ...props }, ref) => (
  <AvatarPrimitive.Root
    className={cn(
      'relative flex shrink-0 items-center justify-center overflow-hidden rounded-full',
      appearance === 'primary' && 'bg-primary',
      appearance === 'secondary' && 'bg-secondary',
      appearance === 'neutral' && 'bg-neutral',
      size === 'small' && 'size-4',
      size === 'medium' && 'size-8',
      className,
    )}
    ref={ref}
    {...props}
  />
))
Avatar.displayName = AvatarPrimitive.Root.displayName

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image
    className={cn('aspect-square h-full w-full', className)}
    ref={ref}
    {...props}
  />
))
AvatarImage.displayName = AvatarPrimitive.Image.displayName

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    className={cn('text-inverse font-text-s', className)}
    ref={ref}
    {...props}
  />
))
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName

export { Avatar, AvatarImage, AvatarFallback }
