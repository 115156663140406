'use client'

import * as React from 'react'

import * as LabelPrimitive from '@radix-ui/react-label'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@design-system/lib/utils'

const labelVariants = cva(
  'text-subtle font-label peer-disabled:cursor-not-allowed peer-disabled:opacity-70 peer-focus:text-primary',
)

export interface LabelProps {
  hint?: React.ComponentType
}

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof labelVariants> &
    LabelProps
>(({ className, hint: Hint, ...props }, ref) => {
  if (!Hint)
    return (
      <LabelPrimitive.Root
        className={cn(labelVariants())}
        ref={ref}
        {...props}
      />
    )
  return (
    <div
      className={cn(
        className,
        Hint && 'flex items-center gap-x-xs hover:cursor-pointer',
      )}
    >
      <LabelPrimitive.Root
        className={cn(labelVariants())}
        ref={ref}
        {...props}
      />
      <Hint />
    </div>
  )
})
Label.displayName = LabelPrimitive.Root.displayName

export { Label }
