import * as React from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@design-system/lib/utils'

import { Text } from '../typography/text'

const calloutVariants = cva(
  'relative w-full rounded-lg p-m flex gap-x-m flex-nowrap',
  {
    variants: {
      variant: {
        default: 'bg-neutral [&>svg]:text-subtle',
        ai: 'bg-gradient-to-r from-primary-start/10 to-primary-stop/10 [&>svg]:text-primary text-primary',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

/**
 * __Callout__
 *
 * @template Built from shadcn/ui Alert component 'https://ui.shadcn.com/docs/components/alert'
 *
 * @see: 'https://www.figma.com/file/qbcszGehTivEsGAzxFdrDN/OpTech-%2F-Design-System?type=design&node-id=435-272&mode=design&t=dtRN0hNyQMWL3IeO-0'
 *
 * @example
 * <Callout icon={ExampleIcon}>
 *  <CalloutDescription>Increase automation rate between 5-10% with this change.</CalloutDescription>
 * </Callout>
 */

const Callout = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof calloutVariants> & {
      icon: React.ComponentType<{ size?: string | number; className?: string }>
    }
>(({ className, variant, icon: Icon, children, ...props }, ref) => (
  <div
    className={cn(calloutVariants({ variant }), className)}
    ref={ref}
    role="alert"
    {...props}
  >
    {Icon && <Icon className="size-4 shrink-0" />}
    <Text variant="p-s">{children}</Text>
  </div>
))
Callout.displayName = 'Callout'

const CalloutDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ ...props }, ref) => <div ref={ref} {...props} />)
CalloutDescription.displayName = 'CalloutDescription'

export { Callout, CalloutDescription }
