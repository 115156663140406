import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const getNumberDisplay = (
  number: number | null,
  units?: 'mins' | 'hrs' | 'secs' | '%',
) => {
  const numberDisplay = number !== null ? Math.round(number) : '-'

  const unitSeparator = units === '%' ? '' : ' '

  const unitsDisplay =
    units && number !== null ? `${unitSeparator}${units}` : ''

  return `${numberDisplay}${unitsDisplay}`
}
